/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $("#loader").hide();

        var searchFormURL = $("#searchCustForm").val();
        var provinceURL = $("#provinceURL").val();

        $("#countrySelect").on("change", function(){

          var selectedCountryName = $(this).val();
          var selectedCountry = $(this).find(":selected").data('country-id');

          console.log(selectedCountry);

          if(selectedCountryName === 'Canada'){
            $.ajax({
               url: provinceURL,
                method: 'post',
                data: {countryID: selectedCountry},
                success: function(response){
                  console.log(response);

                  var provinces = JSON.parse(response);

                  $("#provinceSelect").empty();


                  $.each(provinces, function(province){
                    console.log(this);
                    $("#provinceSelect").append("<option>"+this+"</option>");
                  });

                  $("#regionInput").slideUp(200);
                  $("#provinceInput").slideDown(200);
                  $("#provinceSelect").attr("disabled", false);
                }
            });
          }

          if(selectedCountryName === 'Armenia' || selectedCountryName === 'Georgia'){
                  $("#provinceInput").slideUp(200);
                  $("#regionInput").slideDown(200);
                  $("#regionInput input").attr("disabled", false);
          }

          

        });

        $(".btn-download-manifest").on('click', function(e){
          $("#loader").fadeIn('fast');
          setTimeout(function() {
              $('#loader').fadeOut('fast');
          }, 7000);

        });

        $(".close-alert").on('click', function(e){
          $(".alert-success").remove();
        });
        
        $(".add-new-customer-btn").on('click', function(e){

            e.preventDefault();
            $("#newCustomerModal").modal('show');

         });


        
        $(".mobile-nav-toggle").click(function(e){
          $(".mobile-navigation-container").toggleClass("animate");
          e.preventDefault();
        });

        $(".add-new-country-btn").on('click', function(e){

            e.preventDefault();
            $("#newCountryModal").modal('show');

         });

        $(".form-select2").select2();

        $(".btn-parcel-add-manifest").on('click', function(e){

            e.preventDefault();
            $("#newParcelManifestModal").modal('show');

         });

        $('.content-cats-search').select2({
          maximumSelectionLength: 8,
          tags: true,

        });


        $("#submitQuote").on('click', function(e){

            var quoteFormURL = $("#formURL").val();
            var quoteFormData = $("#quoteForm").serialize();
            var quoteForm = $("#quoteForm");

            e.preventDefault();

              $.ajax({
                    url: quoteFormURL,
                    method: "POST",
                    data: quoteFormData,
                    success: function(response){

                        console.log(response);
                        $("#cost_shipping").html("$"+response);

                    }
              });

        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'staff': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $(".btn-add-new-staff").on('click', function(e){

            e.preventDefault();
            $("#newStaffModal").modal('show');

         });
      }
    },
    // Home page
    'new_manifest': {
      init: function() {
        // JavaScript to be fired on the home page
        function removeElement(array, elem) {
            var index = array.indexOf(elem);
            if (index > -1) {
                array.splice(index, 1);
            }
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        var parcelIDs = [];

        $("body").on('click', '.btn-remove-parcel-manifest', function(e){
            e.preventDefault();
            var parcelID = $(this).data("parcel-id");
            var parcelWeight = $(this).data("parcel-weight");
            var parcelCount = $("#parcelCount").val();
            var totalWeight = $("#totalWeight").val();

            if( confirm("Remove Parcel from Manifest?") ){
              $("#parcel"+parcelID).remove();
              parcelIDs.splice($.inArray(parcelID, parcelIDs),1);
              

              $("#parcelCount").val(parcelCount - 1);
              $("#parcelTotal").html(parcelCount - 1);
              $("#totalWeight").val(totalWeight - parcelWeight);
              $("#parcelWeight").html(totalWeight - parcelWeight);

            }else{
                return false;
            }
        });

        $(".btn-generate-manifest").on("click", function(e){
            
            $("#manifestForm")[0].submit();

        });

        $(".btn-add-parcel-to-manifest").on("click", function(e){
            e.preventDefault();

            var addBtn = $(this);

            var parcelCode = $("#parcelCodeInput").val();
            var totalWeight = $("#parcelWeight").html();
            var totalPieces = $("#parcelTotal").html();
            var packageURL = $("#packageURL").val();
            var parcelTable = $("#parcelTable");
            var parcelCount = $("#parcelCount").val();


            addBtn.attr("disabled", true);

            $.ajax({
               url: packageURL,
                method: 'post',
                data: {code: parcelCode},
                success: function(response){
                 

                  if(response !== "error"){
                    var package = JSON.parse(response);

                    var weight = parseFloat(totalWeight);
                    totalWeight = weight + parseFloat(package.parcel.parcel_weight);

                    console.log(package);

                    addBtn.attr("disabled", false);
                    
                   

                    if($.inArray(package.parcel.ID, parcelIDs) > -1){
                      $("#parcelCodeInput").val("");
                      $("#parcelCodeInput").focus();
                      addBtn.parent().append("<p><span class='fa fa-exclamation-triangle text-danger'></span> "+parcelCode+" Already Exists!</p>");
                    }else{
                        var parcelContents = JSON.parse(package.parcel.parcel_contents);
                        var cleanedContents = " ";

                        $.each(parcelContents, function(key, content){
                          if(cleanedContents === " "){
                            cleanedContents = cleanedContents + content;
                          }
                          else{
                            cleanedContents = cleanedContents + "<br>" + content;
                          }
                          
                        });
                        var jsonPretty = JSON.stringify(parcelContents, null, '\t');
                        parcelCount++;
                        $("#parcelCodeInput").val("");
                        $("#parcelCodeInput").focus();
                        $("#parcelWeight").html(totalWeight);
                        $("#parcelTotal").html(parseFloat(totalPieces) + 1);
                        $("#totalWeight").val(totalWeight);
                        parcelTable.find("tbody").prepend("<tr id='parcel"+package.parcel.ID+"'><td>"+parcelCount+" <input type='hidden' name='parcelIDArray[]' value='"+package.parcel.ID+"'></td><td>"+package.sender.first_name+" "+package.sender.family_name+"<br><small>"+package.sender.address_1+" "+package.sender.address_2+" "+package.sender.country+" Tel: "+package.sender.phone_number+"</td><td>"+package.receiver.first_name+" "+package.receiver.family_name+"<br><small>"+package.receiver.address_1+" "+package.receiver.address_2+" "+package.receiver.country+" Tel: "+package.receiver.phone_number+"</td><td>"+package.parcel.parcel_weight+" "+package.parcel.weight_type+"</td><td>"+package.parcel.tracking_code+"</td><td>"+cleanedContents+"</td><td>"+package.parcel.parcel_size+" "+package.parcel.size_type+"</td><td>$"+package.parcel.total_value+"</td><td><button class='btn btn-sm btn-danger btn-remove-parcel-manifest' data-parcel-id='"+package.parcel.ID+"' data-parcel-weight='"+package.parcel.parcel_weight+"'><span class='fa fa-trash'></span> Remove</button></td></tr>");
                        parcelIDs.push(package.parcel.ID);

                        
                        addBtn.parent().append("<p><span class='fa fa-check-circle text-success'></span> Parcel Added!</p>");
                   
                    
                    }
                  }
                  else{
                      confirm("Parcel Not Found. Try Again!");
                      $("#parcelCodeInput").val("");
                      $("#parcelCodeInput").focus();
                      addBtn.attr("disabled", false);
                      
                  }
                  

                
                }
            });

        });
      }
    },
    'edit_manifest': {
      init: function() {
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        var parcelIDs = [];
        
     

        $("body").on('click', '.btn-remove-parcel-manifest', function(e){
            e.preventDefault();
            var parcelID = $(this).data("parcel-id");
            var parcelWeight = $(this).data("parcel-weight");
            var parcelCount = $("#parcelCount").val();
            var totalWeight = $("#totalWeight").val();

            if( confirm("Remove Parcel from Manifest?") ){
              $("#parcel"+parcelID).remove();
              parcelIDs.splice($.inArray(parcelID, parcelIDs),1);

              console.log(parcelIDs);
              $("#parcelCount").val((parcelCount - 1));
              $("#parcelTotal").html((parcelCount - 1));
              $("#totalWeight").val((totalWeight - parcelWeight));
              $("#parcelWeight").html((totalWeight - parcelWeight));
              

            }else{
                return false;
            }
        });

        $(".btn-generate-manifest").on("click", function(e){
            
            $("#manifestForm")[0].submit();

        });

        $(".btn-add-parcel-to-manifest").on("click", function(e){
            e.preventDefault();

            var addBtn = $(this);

            var parcelCode = $("#parcelCodeInput").val();
            var totalWeight = $("#parcelWeight").html();
            var totalPieces = $("#parcelTotal").html();
            var packageURL = $("#packageURL").val();
            var parcelTable = $("#parcelTable");
            var parcelCount = $("#parcelCount").val();



            addBtn.attr("disabled", true);

            $.ajax({
                url: packageURL,
                method: 'post',
                data: {code: parcelCode},
                success: function(response){
                 

                  if(response !== "error"){
                    var package = JSON.parse(response);

                    var weight = parseFloat(totalWeight);
                    totalWeight = weight + parseFloat(package.parcel.parcel_weight);

                    console.log(package);

                    addBtn.attr("disabled", false);
                    
                   

                    if($.inArray(package.parcel.ID, parcelIDs) > -1){
                      $("#parcelCodeInput").val("");
                      $("#parcelCodeInput").focus();
                      addBtn.parent().append("<p><span class='fa fa-exclamation-triangle text-danger'></span> "+parcelCode+" Already Exists!</p>");
                    }else{
                        var parcelContents = JSON.parse(package.parcel.parcel_contents);
                        var cleanedContents = " ";
                        parcelCount++;
                        $.each(parcelContents, function(key, content){
                          if(cleanedContents === " "){
                            cleanedContents = cleanedContents + content;
                          }
                          else{
                            cleanedContents = cleanedContents + "<br>" + content;
                          }
                          
                        });
                        var jsonPretty = JSON.stringify(parcelContents, null, '\t');

                        $("#parcelCodeInput").val("");
                        $("#parcelCodeInput").focus();
                        $("#parcelWeight").html(totalWeight);
                        $("#parcelTotal").html(parcelCount);
                        $("#totalWeight").val(totalWeight);
                        $("#parcelCount").val(parcelCount);
                        parcelTable.find("tbody").prepend("<tr id='parcel"+package.parcel.ID+"'><td>"+parcelCount+" <input type='hidden' name='parcelIDArray[]' value='"+package.parcel.ID+"'></td><td>"+package.sender.first_name+" "+package.sender.family_name+"<br><small>"+package.sender.address_1+" "+package.sender.address_2+" "+package.sender.country+" Tel: "+package.sender.phone_number+"</td><td>"+package.receiver.first_name+" "+package.receiver.family_name+"<br><small>"+package.receiver.address_1+" "+package.receiver.address_2+" "+package.receiver.country+" Tel: "+package.receiver.phone_number+"</td><td>"+package.parcel.parcel_weight+" "+package.parcel.weight_type+"</td><td>"+package.parcel.tracking_code+"</td><td>"+cleanedContents+"</td><td>"+package.parcel.parcel_size+" "+package.parcel.size_type+"</td><td>$"+package.parcel.total_value+"</td><td><button class='btn btn-sm btn-danger btn-remove-parcel-manifest' data-parcel-id='"+package.parcel.ID+"' data-parcel-weight='"+package.parcel.parcel_weight+"'><span class='fa fa-trash'></span> Remove</button></td></tr>");
                        parcelIDs.push(package.parcel.ID);

                        addBtn.parent().append("<p><span class='fa fa-check-circle text-success'></span> Parcel Added!</p>");
                   
                        console.log(parcelIDs);
                    }
                  }
                  else{
                      confirm("Parcel Not Found. Try Again!");
                      $("#parcelCodeInput").val("");
                      $("#parcelCodeInput").focus();
                      addBtn.attr("disabled", false);
                      console.log(parcelIDs);
                      
                  }
                  

                
                }
            });

        });
      }
    },
    // Content Categories
    'content_categories': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $(".add-new-category-btn").on('click', function(e){

            e.preventDefault();
            $("#newCategoryModal").modal('show');

         });
      }
    },
    // Content Categories
    'tracking_codes': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $(".load-new-codes-btn").on('click', function(e){

            e.preventDefault();
            $("#newCodesModal").modal('show');

         });
      }
    },
    // New Parcels
    'new_parcel': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        var searchFormURL = $("#searchCustForm").val();
        var provinceURL = $("#provinceURL").val();


        $("#insuranceRequested").on("change", function(){

          console.log("CHECK CHANGE!");
          if(this.checked !== false){
              $("#coverageAmount").slideDown(200);
          }else{
              $("#coverageAmount").slideUp(200);
          }
        });


        $("#homeDeliveryCheck").on("change", function(){
          if(this.checked !== false){
              $("#specialInstructions").val($("#specialInstructions").val() + '**Home Delivery**');
          }else{
              $("#specialInstructions").val($("#specialInstructions").val().replace('**Home Delivery**', ""));
              
          }
        });

        $("#coverageAmount input").on("blur", function(){
            var coverageAmount = $(this).val();
            var percentageVal = coverageAmount * 0.10;
            var totalAmount = $("#totalPayment").val();
            var newTotal = 

            console.log((parseInt(totalAmount) + parseInt(percentageVal)));

            $("#totalPayment").val((parseInt(totalAmount) + parseInt(percentageVal)));


        });


        $("#countrySelect").on("change", function(){

          var selectedCountryName = $(this).val();
          var selectedCountry = $(this).find(":selected").data('country-id');

          console.log(selectedCountry);

          if(selectedCountryName === 'Canada'){
            $.ajax({
               url: provinceURL,
                method: 'post',
                data: {countryID: selectedCountry},
                success: function(response){
                  console.log(response);

                  var provinces = JSON.parse(response);

                  $("#provinceSelect").empty();


                  $.each(provinces, function(province){
                    console.log(this);
                    $("#provinceSelect").append("<option>"+this+"</option>");
                  });

                  $("#regionInput").slideUp(200);
                  $("#provinceInput").slideDown(200);
                  $("#provinceSelect").attr("disabled", false);
                }
            });
          }

          if(selectedCountryName === 'Armenia'){
                  $("#provinceInput").slideUp(200);
                  $("#regionInput").slideDown(200);
                  $("#regionInput input").attr("disabled", false);
          }

          

        });
       
        $('.content-cats-search').select2({
          maximumSelectionLength: 8,
          tags: true,

        });

        $("body").on('click', '.add-new-customer-btn', function(e){

           

           
           var customerType = $(this).data('customer-type');

           $("#customerType").val(customerType);



        });

        $(".btn-submit-parcel-add").on('click', function(e){
            
            $(this).attr("disabled", true);
            $(this).addClass("btn-disabled");
            $(this).removeClass("btn-primary");
            e.preventDefault();

            var senderID = $("#senderID").val().length;
            var receiverID = $("#receiverID").val().length;

            if(senderID !== 0 && receiverID !== 0){
                  
              $("#parcelForm").submit();
              var redirect = $("#redirectURL").val();
              window.location.assign(redirect);
                        
            }else{

                alert("Missing Customer Information!");
                $(this).attr("disabled", false);
                
                $(this).removeClass("btn-disabled");
                $(this).addClass("btn-primary");

                return false;
            }

            
            
        });


        $("body").on('click', '.add-new-customer-modal', function(e){

          

           var formURL = $("#customerFormURL").val();
           var customerType = $("#customerType").val();
           var formData = $("#customerForm").serialize();
           var customerInfo = $(".customer-info-"+customerType);

           console.log(formData);

            $("#customerForm").validate({
                // Specify validation rules
                rules: {
                  // The key name on the left side is the name attribute
                  // of an input field. Validation rules are defined
                  // on the right side
                  family_name: "required",
                  first_name: "required",
                  phone_number: "required",
                  postal_code: "required",
                  city: "required",
                  email: {
                    required: false,
                    // Specify that email should be validated
                    // by the built-in "email" rule
                    email: true
                  },
                  country: {
                    required: true,
                   
                  },
                },
                // Specify validation error messages
                messages: {
                  first_name: "Please enter customer firstname",
                  family_name: "Please enter customer lastname",
                  email: "Please enter a valid email address"
                },
                // Make sure the form is submitted to the destination defined
                // in the "action" attribute of the form when valid
                submitHandler: function(form) {
                  e.preventDefault();
                  $.ajax({
                      url: formURL,
                      method: 'post',
                      data: formData,
                      success: function(response){
                        customer = JSON.parse(response);
                        console.log(customer);
                        if(customerType === 'sender'){
                              $(".sender-name").html(customer.first_name + ' ' + customer.family_name);
                              $(".sender-address").html(customer.address_1 + ' ' + customer.address_2);
                              
                              if(customer.country === 'Armenia'){
                                $(".sender-province").html(customer.region);
                                $(".sender-country").html(customer.city);
                                $(".sender-city").html(customer.country);
                              }else{
                                $(".sender-province").html(customer.province);

                                $(".sender-city").html(customer.city);
                                $(".sender-country").html(customer.country);
                              }
                              
                              $(".sender-postal").html(customer.postal_code);
                              $(".sender-phone").html(customer.phone_number);
                              $(".sender-email").html(customer.email_address);
                              $(".sender-notes").html(customer.notes);
                              $("#senderID").val(customer.ID);
                              
                              customerInfo.slideDown(200);
                              $("#newCustomerModal").modal('hide');
                              $("#customerForm").trigger("reset");
                          }
                          if(customerType === 'receiver'){
                              $(".receiver-name").html(customer.first_name + ' ' + customer.family_name);
                              $(".receiver-address").html(customer.address_1 + ' ' + customer.address_2);
                              if(customer.country === 'Armenia' || customer.country === 'Georgia'){
                                $(".receiver-province").html(customer.region);
                                $(".receiver-country").html(customer.city);
                                $(".receiver-city").html(customer.country);
                              }else{
                                $(".receiver-province").html(customer.province);

                                $(".receiver-city").html(customer.city);
                                $(".receiver-country").html(customer.country);
                              }
                              $(".receiver-postal").html(customer.postal_code);
                              $(".receiver-phone").html(customer.phone_number);
                              $(".receiver-email").html(customer.email_address);
                              $(".receiver-notes").html(customer.notes);
                              $("#receiverID").val(customer.ID);
                              customerInfo.slideDown(200);
                              $("#newCustomerModal").modal('hide');
                              $("#customerForm").trigger("reset");
                          }

                      }
                });
                return false;
              }
            });
        });


        

        $("body").on('click', 'li.add-customer-parcel', function(e){
            
            e.preventDefault();

            var customerID = $(this).data('customer-id');
            var customerType = $(this).parent().parent().parent().find(".customer-ajax-input").data('cust-type');
            var getCustomerIDForm = $("#customerByIDForm").val();
            var customerInfo = $(this).parent().parent().parent().find(".customer-info");

            console.log(customerType);

            $.ajax({
                  url: getCustomerIDForm,
                  method: "POST",
                  data: { customerID : customerID },
                  success: function(customers){


                    if(customers !== 'error'){

                      
                      var customer = JSON.parse(customers);
                      customer = customer[0];

                      if(customerType === 'sender'){
                          $(".sender-name").html(customer.first_name + ' ' + customer.family_name);
                          $(".sender-address").html(customer.address_1 + ' ' + customer.address_2);
                          if(customer.country === 'Armenia'){
                            $(".sender-province").html(customer.region);
                            $(".sender-country").html(customer.city);
                            $(".sender-city").html(customer.country);
                          }else{
                            $(".sender-province").html(customer.province);

                            $(".sender-city").html(customer.city);
                            $(".sender-country").html(customer.country);
                          }
                          $(".sender-postal").html(customer.postal_code);
                          $(".sender-phone").html(customer.phone_number);
                          $(".sender-email").html(customer.email_address);
                          $(".sender-notes").html(customer.notes);
                          $("#senderID").val(customer.ID);
                          customerInfo.slideDown(200);
                      }
                      else{
                          $(".receiver-name").html(customer.first_name + ' ' + customer.family_name);
                          $(".receiver-address").html(customer.address_1 + ' ' + customer.address_2);
                          if(customer.country === 'Armenia'){
                            $(".receiver-province").html(customer.region);
                            $(".receiver-country").html(customer.city);
                            $(".receiver-city").html(customer.country);
                          }else{
                            $(".receiver-province").html(customer.province);

                            $(".receiver-city").html(customer.city);
                            $(".receiver-country").html(customer.country);
                          }
                          $(".receiver-postal").html(customer.postal_code);
                          $(".receiver-phone").html(customer.phone_number);
                          $(".receiver-email").html(customer.email_address);
                          $(".receiver-notes").html(customer.notes);
                          $("#receiverID").val(customer.ID);
                          customerInfo.slideDown(200);
                      }

                    }
                      
                  }
            });

           


        });


        $("body").on('click', '.edit-customer', function(e){
            

            e.preventDefault();
            $("#editCustomerModal").modal('show');

            
            var customerType = $(this).data('custype');
            

            var editCustomerForm = $("#editCustomerForm");
            var editCustomerFormURL = $("#editCustomerFormURL").val();
            var getCustomerIDForm = $("#editCustomerByIDForm").val();
            var customerNum;
            

            if(customerType === 'sender'){
               customerNum = $("#senderID").val();
               
            }
            if(customerType === 'receiver'){
               customerNum = $("#receiverID").val();
            }
            
            editCustomerForm.css("opacity", 0.2);

           
            $.ajax({
                  url: editCustomerFormURL,
                  method: "POST",
                  data: { customerID : customerNum },
                  success: function(customers){


                    if(customers !== 'error'){

                      
                      var customer = JSON.parse(customers);
                      customer = customer[0];

                      $("#edit_first_name").val(customer.first_name);
                      $("#edit_family_name").val(customer.family_name);
                      $("#edit_phone_number").val(customer.phone_number);
                      $("#edit_email").val(customer.email_address);
                      $("#edit_address1").val(customer.address_1);
                      $("#edit_address2").val(customer.address_2);
                      $("#edit_city").val(customer.city);
                      $("#edit_postal_code").val(customer.postal_code);
                      $("#edit_notes").val(customer.notes);
                      $("#editCustomerType").val(customerType);
                      $("#editCustomerID").val(customerNum);
                          
                      editCustomerForm.css("opacity", 1);

                    }
                    else{
                      alert("error retrieving customer. Try again or contact support.");
                    }
                  }
            });

           


        });


        $("body").on('click', '.update-btn-customer-modal', function(e){

          e.preventDefault();
          
          

           var formURL = $("#updateCustomerFormURL").val();
           var customerType = $("#editCustomerType").val();

           var formData = $("#editCustomerForm").serialize();

           var customerInfo = $(".customer-info-"+customerType);

           

           

            $.ajax({
                      url: formURL,
                      method: 'post',
                      data: formData,
                      success: function(response){
                        
                        customer = JSON.parse(response);
                        customer = customer[0];

                        if(customerType === 'sender'){
                              $(".sender-name").html(customer.first_name + ' ' + customer.family_name);
                              $(".sender-address").html(customer.address_1 + ' ' + customer.address_2);
                              
                              if(customer.country === 'Armenia'){
                                $(".sender-province").html(customer.region);
                                $(".sender-country").html(customer.city);
                                $(".sender-city").html(customer.country);
                              }else{
                                $(".sender-province").html(customer.province);

                                $(".sender-city").html(customer.city);
                                $(".sender-country").html(customer.country);
                              }
                              
                              $(".sender-postal").html(customer.postal_code);
                              $(".sender-phone").html(customer.phone_number);
                              $(".sender-email").html(customer.email_address);
                              $(".sender-notes").html(customer.notes);
                              $("#senderID").val(customer.ID);
                              
                              
                          }
                          else{
                              $(".receiver-name").html(customer.first_name + ' ' + customer.family_name);
                              $(".receiver-address").html(customer.address_1 + ' ' + customer.address_2);
                              if(customer.country === 'Armenia' || customer.country === 'Georgia'){
                                $(".receiver-province").html(customer.region);
                                $(".receiver-country").html(customer.city);
                                $(".receiver-city").html(customer.country);
                              }else{
                                $(".receiver-province").html(customer.province);

                                $(".receiver-city").html(customer.city);
                                $(".receiver-country").html(customer.country);
                              }
                              $(".receiver-postal").html(customer.postal_code);
                              $(".receiver-phone").html(customer.phone_number);
                              $(".receiver-email").html(customer.email_address);
                              $(".receiver-notes").html(customer.notes);
                              $("#receiverID").val(customer.ID);
                              //customerInfo.slideDown(200);

                          }

                          $("#editCustomerModal").modal('hide');
                       }
                });
                return false;
            });
       

        $(".customer-ajax-input").on('keyup', function(){

            var inputVal = $(this).val();
            var customerAjaxBox = $(this).parent().parent().find(".customer-ajax-box");

            if(inputVal.length >= 3){

                customerAjaxBox.fadeIn(300);
                customerAjaxBox.find(".loader").fadeIn(100);

                $.ajax({
                    url: searchFormURL,
                    method: "POST",
                    data: { searchInput : inputVal },
                    success: function(response){

                      customerAjaxBox.find("ul").empty();
                      
                      customerAjaxBox.find("ul").html(response);

                      customerAjaxBox.find(".loader").fadeOut(100);
                      

                    }
              });

               
            }
        });






         $(".customer-ajax-input").on('blur', function(){

             $(this).parent().parent().find(".customer-ajax-box").fadeOut(300);
          });


         
      }
    },
    // Edit Parcels
    'edit_parcel': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {

        var searchFormURL = $("#searchCustForm").val();
        var provinceURL = $("#provinceURL").val();

        $("#insuranceRequested").on("change", function(){

          console.log("CHECK CHANGE!");
          if(this.checked !== false){
              $("#coverageAmount").slideDown(200);
          }else{
              $("#coverageAmount").slideUp(200);
          }
        });

        $("#coverageAmount input").on("blur", function(){
            var coverageAmount = $(this).val();
            var percentageVal = coverageAmount * 0.10;
            var totalAmount = $("#totalPayment").val();
            var newTotal = 

            console.log((parseInt(totalAmount) + parseInt(percentageVal)));

            $("#totalPayment").val((parseInt(totalAmount) + parseInt(percentageVal)));


        });


       

        $("#countrySelect").on("change", function(){

          var selectedCountryName = $(this).val();
          var selectedCountry = $(this).find(":selected").data('country-id');

          console.log(selectedCountry);

          if(selectedCountryName === 'Canada'){
            $.ajax({
               url: provinceURL,
                method: 'post',
                data: {countryID: selectedCountry},
                success: function(response){
                  console.log(response);

                  var provinces = JSON.parse(response);

                  $("#provinceSelect").empty();


                  $.each(provinces, function(province){
                    console.log(this);
                    $("#provinceSelect").append("<option>"+this+"</option>");
                  });

                  $("#regionInput").slideUp(200);
                  $("#provinceInput").slideDown(200);
                  $("#provinceSelect").attr("disabled", false);
                }
            });
          }

          if(selectedCountryName === 'Armenia' || selectedCountryName === 'Georgia'){
                  $("#provinceInput").slideUp(200);
                  $("#regionInput").slideDown(200);
                  $("#regionInput input").attr("disabled", false);
          }

          

        });
       
        $('.content-cats-search').select2({
          maximumSelectionLength: 8,
          tags: true,

        });

        $("body").on('click', '.add-new-customer-btn', function(e){

           

           
           var customerType = $(this).data('customer-type');

           $("#customerType").val(customerType);



        });

        $(".btn-submit-parcel-add").on('click', function(e){
            

            var senderID = $("#senderID").val().length;
            var receiverID = $("#receiverID").val().length;

            if(senderID !== 0 && receiverID !== 0){
                  
              $("#parcelForm").submit();
              var redirect = $("#redirectURL").val();
              window.location.assign(redirect);
                        
            }else{

                alert("Missing Customer Information!");
            }

            
            
        });


        $("body").on('click', '.add-new-customer-modal', function(e){

          

           var formURL = $("#customerFormURL").val();
           var customerType = $("#customerType").val();
           var formData = $("#customerForm").serialize();
           var customerInfo = $(".customer-info-"+customerType);

           console.log(formData);

            $("#customerForm").validate({
                // Specify validation rules
                rules: {
                  // The key name on the left side is the name attribute
                  // of an input field. Validation rules are defined
                  // on the right side
                  family_name: "required",
                  first_name: "required",
                  phone_number: "required",
                  postal_code: "required",
                  city: "required",
                  email: {
                    required: false,
                    // Specify that email should be validated
                    // by the built-in "email" rule
                    email: true
                  },
                  country: {
                    required: true,
                   
                  },
                },
                // Specify validation error messages
                messages: {
                  first_name: "Please enter customer firstname",
                  family_name: "Please enter customer lastname",
                  email: "Please enter a valid email address"
                },
                // Make sure the form is submitted to the destination defined
                // in the "action" attribute of the form when valid
                submitHandler: function(form) {
                  e.preventDefault();
                  $.ajax({
                      url: formURL,
                      method: 'post',
                      data: formData,
                      success: function(response){
                        customer = JSON.parse(response);
                        console.log(customer);
                        if(customerType === 'sender'){
                              $(".sender-name").html(customer.first_name + ' ' + customer.family_name);
                              $(".sender-address").html(customer.address_1 + ' ' + customer.address_2);
                              
                              if(customer.country === 'Armenia'){
                                $(".sender-province").html(customer.region);
                                $(".sender-country").html(customer.city);
                                $(".sender-city").html(customer.country);
                              }else{
                                $(".sender-province").html(customer.province);

                                $(".sender-city").html(customer.city);
                                $(".sender-country").html(customer.country);
                              }
                              
                              $(".sender-postal").html(customer.postal_code);
                              $(".sender-phone").html(customer.phone_number);
                              $(".sender-notes").html(customer.notes);
                              $("#senderID").val(customer.ID);
                              
                              customerInfo.slideDown(200);
                              $("#newCustomerModal").modal('hide');
                              $("#customerForm").trigger("reset");
                          }
                          if(customerType === 'receiver'){
                              $(".receiver-name").html(customer.first_name + ' ' + customer.family_name);
                              $(".receiver-address").html(customer.address_1 + ' ' + customer.address_2);
                              if(customer.country === 'Armenia'  || customer.country === 'Georgia'){
                                $(".receiver-province").html(customer.region);
                                $(".receiver-country").html(customer.city);
                                $(".receiver-city").html(customer.country);
                              }else{
                                $(".receiver-province").html(customer.province);

                                $(".receiver-city").html(customer.city);
                                $(".receiver-country").html(customer.country);
                              }
                              $(".receiver-postal").html(customer.postal_code);
                              $(".receiver-phone").html(customer.phone_number);
                              $(".receiver-notes").html(customer.notes);
                              $("#receiverID").val(customer.ID);
                              customerInfo.slideDown(200);
                              $("#newCustomerModal").modal('hide');
                              $("#customerForm").trigger("reset");
                          }

                      }
                });
                return false;
              }
            });
        });


        

        $("body").on('click', 'li.add-customer-parcel', function(e){
            
            e.preventDefault();

            var customerID = $(this).data('customer-id');
            var customerType = $(this).parent().parent().parent().find(".customer-ajax-input").data('cust-type');
            var getCustomerIDForm = $("#customerByIDForm").val();
            var customerInfo = $(this).parent().parent().parent().find(".customer-info");

            console.log(customerType);

            $.ajax({
                  url: getCustomerIDForm,
                  method: "POST",
                  data: { customerID : customerID },
                  success: function(customers){


                    if(customers !== 'error'){

                      
                      var customer = JSON.parse(customers);
                      customer = customer[0];

                      if(customerType === 'sender'){
                          $(".sender-name").html(customer.first_name + ' ' + customer.family_name);
                          $(".sender-address").html(customer.address_1 + ' ' + customer.address_2);
                          if(customer.country === 'Armenia'){
                            $(".sender-province").html(customer.region);
                            $(".sender-country").html(customer.city);
                            $(".sender-city").html(customer.country);
                          }else{
                            $(".sender-province").html(customer.province);

                            $(".sender-city").html(customer.city);
                            $(".sender-country").html(customer.country);
                          }
                          $(".sender-postal").html(customer.postal_code);
                          $(".sender-phone").html(customer.phone_number);
                          $("#senderID").val(customer.ID);
                          customerInfo.slideDown(200);
                      }
                      else{
                          $(".receiver-name").html(customer.first_name + ' ' + customer.family_name);
                          $(".receiver-address").html(customer.address_1 + ' ' + customer.address_2);
                          if(customer.country === 'Armenia'){
                            $(".receiver-province").html(customer.region);
                            $(".receiver-country").html(customer.city);
                            $(".receiver-city").html(customer.country);
                          }else{
                            $(".receiver-province").html(customer.province);

                            $(".receiver-city").html(customer.city);
                            $(".receiver-country").html(customer.country);
                          }
                          $(".receiver-postal").html(customer.postal_code);
                          $(".receiver-phone").html(customer.phone_number);
                          $("#receiverID").val(customer.ID);
                          customerInfo.slideDown(200);
                      }

                    }
                      
                  }
            });

           


        });
       

        $(".customer-ajax-input").on('keyup', function(){

            var inputVal = $(this).val();
            var customerAjaxBox = $(this).parent().parent().find(".customer-ajax-box");

            if(inputVal.length >= 3){

                customerAjaxBox.fadeIn(300);
                customerAjaxBox.find(".loader").fadeIn(100);

                $.ajax({
                    url: searchFormURL,
                    method: "POST",
                    data: { searchInput : inputVal },
                    success: function(response){

                      customerAjaxBox.find("ul").empty();
                      
                      customerAjaxBox.find("ul").html(response);

                      customerAjaxBox.find(".loader").fadeOut(100);
                      

                    }
              });

               
            }
        });


         $(".customer-ajax-input").on('blur', function(){

             $(this).parent().parent().find(".customer-ajax-box").fadeOut(300);
          });


         
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.